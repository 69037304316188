import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import AnalyticsServices from "../../api/services/analytics";
import {eventNameParsing, getTitle, getHeaderText} from "../../utils/eventNameParsing";
import '../../styles/views/tableAnalytics.css';


const TableAnalytics = () => {

    const { period, dataType } = useParams();
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await AnalyticsServices.getEvents(period, dataType);
                if (response.status === 200) {
                    setData(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching analytics:', error);
            }
        };

        fetchData();
    },[] );


    // Sort data by length of params array in descending order
    const sortedData = [...data].sort((a, b) => b.params.length - a.params.length);


    return (
        <div className="main-container-table">
            {sortedData && sortedData.length > 0 ? (
                sortedData.map((event, index) => {
                    const { name, description } = eventNameParsing(event.event_name);

                    return (
                        <div key={index} className="event-section">
                            {/* Title and Description */}
                            <h2 className={"event-title"}>{name}</h2>
                            <p className={"event-description"}>{description}</p>

                            {/* Parameter Table */}
                            <div className="table-container">
                                <table className="styled-table">
                                    <thead>
                                        <tr>
                                            <th>{getTitle(event.params.map((param) => param.name))}</th>
                                            <th>{getHeaderText(event.params.map((param) => param.name))}</th>
                                            <th>Nombre de users</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {event.params.map((param, idx) => (
                                            <tr key={idx}>
                                                <td>{param.value || '-'}</td>
                                                <td>{param.eventCount}</td>
                                                <td>{param.uniqueUsers}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="table">
                    <p>Loading...</p>
                </div>
            )}
        </div>
    );

}

export default TableAnalytics;