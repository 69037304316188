import React from "react";
import {createBrowserRouter} from "react-router-dom";
import Main from "../views/main";
import CardAnalytics from "../views/analytics/cardAnalytics";
import TableAnalytics from "../views/analytics/tableAnalytics";


const routes = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
    },
    {
        path: "/cardWidget/:period",
        element: <CardAnalytics />,
    },
    {
        path: "/tablesWidget/:dataType/:period",
        element: <TableAnalytics />,
    }
]);

export default routes;