
import logo from "../assets/images/svg/logo_row.svg"
import "../styles/views/main.css"
import { Link } from "react-router-dom";
const Main = () => {
    return (
        <div className={"main"}>
            <div className={"main__header"}>
                <img src={logo} alt={"logo"}/>
                <h1>Widget Frimity</h1>
            </div>

            <div className={"main__content"}>
                <div className={"main__content__cards"}>
                    <h2>Cards</h2>
                    <div className={"main__content__cards__item"}>
                        <Link to={"/cardWidget/yesterday"}>• Card Analytics - Yesterday</Link>
                        <Link to={"/cardWidget/thisWeek"}>• Card Analytics - This week</Link>
                        <Link to={"/cardWidget/thisMonth"}>• Card Analytics - This month</Link>
                        <Link to={"/cardWidget/lastMonth"}>• Card Analytics - Last month</Link>
                    </div>
                </div>

                <div className={"main__content__cards"}>
                    <h2>Tables</h2>
                    <div className={"main__content__cards__item"}>
                        <Link to={"/tablesWidget/usages/yesterday"}>• Usages Tables - Yesterday</Link>
                        <Link to={"/tablesWidget/bookings/yesterday"}>• Bookings Tables - This week</Link>
                        <Link to={"/tablesWidget/app/yesterday"}>• App Tables - This month</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Main;