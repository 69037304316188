import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const AnalyticsServices = {

    getAnalytics: async (period) => {
        return await getRequest(`/analytics/get-analytics/${period}`);
    },

    getEvents: async (period, dataType) => {
        return await getRequest(`/analytics/get-events/${dataType}/${period}`);
    }

}

export default AnalyticsServices;