// services/eventTranslationService.js
export const eventNameParsing = (eventName) => {
    const eventDetails = {
        "activites_vuees": {
            name: "Activités vues",
            description: "Nom des activités visualisées"
        },
        "types_activites_plus_vues": {
            name: "Types d'activités les plus vues",
            description: "Les types d'activités les plus vues"
        },
        "activity_selected": {
            name: "Activité sélectionnée",
            description: "Activité préféré choisie"
        },
        "activity_deselected": {
            name: "Activité désélectionnée",
            description: "Activité retirée des favoris"
        },
        "button_click": {
            name: "Clic sur un bouton",
            description: "Clique sur les boutons de l'application"
        },
        "screen_view": {
            name: "Vue des pages",
            description: "Écrans de l'application vue"
        },
        "app_open": {
            name: "Application ouverte",
            description: "Nombre d'ouvertures de l'application"
        },
        "os_update": {
            name: "Mise à jour du système",
            description: "Mises à jour du système d'exploitation détectées"
        },
        "first_open": {
            name: "Première ouverture",
            description: "Première utilisation de l'application"
        },
        "app_remove": {
            name: "Application supprimée",
            description: "Suppression de l'application"
        },
        "app_update": {
            name: "Mise à jour de l'application",
            description: "Nombre de mises à jour de l'application installées"
        },
        "nombre_invitations_refusees": {
            name: "Nombre d'invitations refusées",
            description: "Invitations envoyées mais refusées"
        },
        "nombre_invitations_envoyees": {
            name: "Nombre d'invitations envoyées",
            description: "Invitations envoyées à d'autres utilisateurs"
        },
        "nombre_invitations_acceptees": {
            name: "Nombre d'invitations acceptées",
            description: "Invitations envoyées et acceptées"
        },
        "nombre_activites_organisees_annulees": {
            name: "Nombre d'activités annulées",
            description: "Activités organisées mais annulées"
        },
        "nombre_invitations_reservations": {
            name: "Nombre de réservations d'invitations",
            description: "Réservations faites via des invitations"
        },
        "nombre_activites_organisees": {
            name: "Nombre d'activités organisées",
            description: "Activités organisées par les utilisateurs"
        },
        "methodes_paiement_utilisees": {
            name: "Méthodes de paiement utilisées",
            description: "Les méthodes de paiement préférées des utilisateurs"
        },
        "frequence_paiements": {
            name: "Fréquence des paiements",
            description: "Nombre de paiements réalisés par les utilisateurs"
        }
    };

    return eventDetails[eventName] || { name: eventName, description: "Description indisponible" };
};



export const getTitle = (paramsNames) => {
    // Check if specific params exist within the array
    if (paramsNames.includes("app_open")) {
        return "Ouverture de l'application";
    } else if (paramsNames.includes("activityNameView")) {
        return "Nom de l'activité";
    } else if (paramsNames.some(param => ["activityType", "activity_type_name", "activity_type_deselect_name"].includes(param))) {
        return "Type d'activité";
    } else if (paramsNames.includes("button_name")) {
        return "Page de l'application";
    } else {
        return "Nom d'une page";
    }
};

export const getHeaderText = (paramsNames) => {
    if (paramsNames.includes("button_name")) {
        return "Nombre de clique";
    }else{
        return "Nombre de vue";
    }
}

// Function to translate screen names to French
const translateScreenName = (name) => {
    const translations = {
        "Animation": "Animation",
        "OnBoarding": "Écran - Onboarding",
        "Welcome": "Écran - Bienvenue",
        "Home": "Activités - Section",
        "Profile": "Écran - Profil",
        "Favoris": "Écran - Favoris",
        "Login": "Écran - Connexion",
        "FeedBack": "Écran - Feedback",
        "SignUpWelcome": "Inscription - Sélection options",
        "SignUpCode": "Inscription - Code de vérification",
        "SignUpProfil": "Inscription - Avatar/pseudo",
        "SignUpLocation": "Inscription - Localisation",
        "SignUpBirth": "Inscription - Date de naissance",
        "SignUpPhone": "Inscription - Ajout téléphone",
        "SignUpPlatform": "Inscription - Plateforme",
        "SignUpActivities": "Inscription - Sélection activité",
        "SignUpFriends": "Inscription - Ajout amis",
        "SignUpEmail": "Inscription - Email/password",
        "NotificationsNews": "Notifications - Actualités",
        "NotificationsInvite": "Notifications- Invitation réservations",
        "NotificationsFriends": "Notifications - Amis",
        "ActivityPartner": "Activités - Activités du partenaire",
        "ActivitiesMap": "Activités - Carte",
        "ActivityItem": "Activités - une activité",
        "ActivitySimilarType": "Activités - Activités similaire",
        "ActivitiesFavorites": "Activités - Liste favoris",
        "ActivitiesForYou": "Activités - Liste pour toi",
        "BookingManage": "Réservation - Gestion de la réservation",
        "BookingSchedule": "Réservations - Choix de la date",
        "BookingManagePricing": "Réservations - Choix des tarifs",
        "BookingPayment": "Réservations - Paiement",
        "BookingManagePayment": "Réservations - Choix pour paiement",
        "BookingActivityPast": "Réservations - Activité passée",
        "BookingActivityCurrent": "Activité en cours",
        "BookingManageParticipants": "Réservations - Vue des participants",
        "BookingPlan": "Réservation - Création (date/participants)",
        "ProfilPhone": "Profil - Téléphone",
        "ProfilPhoneVerify": "Profil - Vérification téléphone",

    };

    return translations[name] || name; // Return the original name if no translation is available
};


