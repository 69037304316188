

import React, { useEffect, useState } from 'react';
import AnalyticsServices from '../../api/services/analytics';
import '../../styles/views/cardAnalytics.css';
import { useParams } from 'react-router-dom';

const CardAnalytics = () => {
    
    const { period } = useParams();
    const [data, setData] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await AnalyticsServices.getAnalytics(period);
                if (response.status === 200) {
                    setData(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching analytics:', error);
            }
        };
        
        fetchData();
    },[] );


    useEffect(() => {
        const reloadAt12_30AM = () => {
            const now = new Date();

            // Calculate next 12:30 AM
            const nextReload = new Date();
            nextReload.setHours(0, 30, 0, 0);  // Set the time to 12:30 AM

            if (now > nextReload) {
                // If the current time is after 12:30 AM today, set for tomorrow
                nextReload.setDate(nextReload.getDate() + 1);
            }

            // Calculate the time remaining until next 12:30 AM
            const timeUntil12_30AM = nextReload - now;
            console.log(`Page will reload in ${timeUntil12_30AM / 1000 / 60} minutes`);

            // Set a timeout to reload the page at the calculated time
            setTimeout(() => {
                window.location.reload();
            }, timeUntil12_30AM);
        };

        reloadAt12_30AM();  // Call the function to schedule the reload

    }, []); // Empty dependency array means this runs once on component mount



    return (
        <div className="main-container">
            <div className="card-body">
                <div className={"container_card"}>
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            index !== 12 &&
                            <div key={index} className={"card"}>
                                <p className={"card-title"}>{item.name}</p>
                                <p className={"card-value"}>{item.value} {item.unit}</p>
                            </div>
                        ))
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CardAnalytics;